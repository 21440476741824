import { useState } from "react";
import Papa from "papaparse";

export const useCsvParser = () => {
  const [parsedData, setParsedData] = useState([]);

  const parseCsv = (file, callback, columnMappings = {}) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        const { data, errors } = result;

        if (errors.length > 0) {
          if (callback) callback({ error: errors });
          return;
        }

        // Define regular expressions for guessing column names
        const regexMappings = {
          comment: /comment|komentārs|koments|komentars/i,
          phone: /phone|telephone|mobile phone|telefons|numurs|mobilais/i,
        };

        // Check for required headers (phone or number)
        const headers = Object.keys(data[0]);
        if (!headers.some((header) => regexMappings.phone.test(header))) {
          window.alert(
            "Nav atrasta galvene ar nosaukumu phone|telephone|mobile phone|telefons|numurs|mobilais"
          );
          if (callback) callback({ error: "Missing required column" });
          return;
        }

        // Find column indices based on provided mappings or regex guesses
        const findColumnIndex = (rows, columnName, regex) =>
          columnMappings[columnName]
            ? rows.findIndex(
                (header) =>
                  header.toLowerCase() ===
                  columnMappings[columnName].toLowerCase()
              )
            : rows.findIndex((header) => regex.test(header));

        const columnIndices = {
          comment: findColumnIndex(headers, "comment", regexMappings.comment),
          phone: findColumnIndex(headers, "phone", regexMappings.phone),
        };

        // Extract data using found column indices
        const extractedData = data.map((row) => {
          const rowData = {};
          Object.entries(columnIndices).forEach(([key, index]) => {
            rowData[key] = index !== -1 ? row[headers[index]] : undefined;
          });
          return rowData;
        });

        setParsedData(extractedData);
        if (callback) callback(extractedData);
      },
    });
  };

  return [parsedData, parseCsv];
};
