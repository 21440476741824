import "./App.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getGroup, getLanguage, getToken, getUserId } from "./functions/tokens";
import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { WebPhoneProvider } from "./functions/WebPhoneContext";
import MainContainer from "./Components/MainContainer";
import LoginScreen, {
  ForgotPasswordScreen,
  PassResetScreen,
} from "./Screens/User/LoginScreen";
import LeadScreen from "./Screens/User/LeadScreen";
import { Logout } from "./Screens/User/LogoutScreen";
import { MainScreen } from "./Screens/MainScreen";
import AdminUsersScreen from "./Screens/Admin/AdminUsers";
import { CampaignScreen, ManageCampaign } from "./Screens/User/CampaignScreen";
import { ImportScreen } from "./Screens/User/ImportScreen";
import { LeadsReport } from "./Screens/Admin/LeadOverviewScreen";
import { NumberManagementScreen } from "./Screens/Admin/AdminNumbers";
import { AdminClientOverviewScreen } from "./Screens/Admin/AdminClientOverviewScreen";
import { AdminClientScreen } from "./Screens/Admin/AdminClientScreen";
import { AdminProjectScreen } from "./Screens/User/ProjectScreen";
import { AdminCampaignScreen } from "./Screens/Admin/AdminCampaignScreen";

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const navigation = useNavigate();
  const [showbar, setShowbar] = useState(true);
  const [margins, setMargins] = useState(true);

  const [locale, setLocale] = useState(
    getLanguage() || navigator.language || "en-GB"
  );

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  useEffect(() => {
    setLoggedin(getToken());
  }, [navigation]);

  // useEffect(() => {
  //   initGoogleMapsApi(isLoggedIn);
  // }, [isLoggedIn]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={"en-GB"}
    >
      {/* <SettingsProvider use={isLoggedIn}> */}
      <WebPhoneProvider
        useProvider={isLoggedIn}
        key={isLoggedIn && getUserId()}
      >
        <MainContainer
          show={isLoggedIn}
          locale={locale}
          showTop={showbar}
          marginOffset={margins}
        >
          <Routes>
            <Route
              exct
              path="/reset-password/:token"
              element={<PassResetScreen />}
            />
            <Route
              exct
              path="/forgot-password"
              element={<ForgotPasswordScreen />}
            />
            {isLoggedIn && getGroup() == 20 && (
              <Route exct path="/main" element={<LeadScreen />} />
            )}
            {isLoggedIn && getGroup() == 0 && (
              <>
                <Route exct path="/main" element={<MainScreen />} />
                <Route
                  exct
                  path="/admin-users"
                  element={<AdminUsersScreen />}
                />
                <Route
                  exct
                  path="/admin-lead-overview"
                  element={<LeadsReport />}
                />
                <Route
                  exct
                  path="/admin-projects"
                  element={<AdminProjectScreen />}
                />
                <Route
                  exct
                  path="/admin-clients"
                  element={<AdminClientOverviewScreen />}
                />
                <Route
                  exct
                  path="/admin-campaigns"
                  element={<AdminCampaignScreen />}
                />
                <Route
                  exct
                  path="/admin-numbers"
                  element={<NumberManagementScreen />}
                />
                <Route
                  exct
                  path="/client/:id"
                  element={<AdminClientScreen />}
                />
                <Route
                  exct
                  path="/admin-campaigns/:id"
                  element={<ManageCampaign />}
                />
                <Route exct path="/admin-imports" element={<ImportScreen />} />
              </>
            )}
            <Route
              exct
              path="/login"
              element={isLoggedIn ? <RedirectWrapper /> : <LoginScreen />}
            />
            <Route
              exct
              path="/logout"
              element={<Logout setLoggedin={setLoggedin} />}
            />

            <Route path="*" element={<RedirectWrapper />} />
          </Routes>
        </MainContainer>
      </WebPhoneProvider>
      {/* </SettingsProvider> */}
    </IntlProvider>
  );
}

export default App;
