import { Button, Card, Select, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  adminGetLeadClientList,
  adminGetNumberStats,
  adminRemoveNumbers,
} from "../../functions/api";

export const AdminCampaignScreen = () => {
  const intl = useIntl();

  const [projects, setProjects] = useState(null);
  const [selected, setSelected] = useState(null);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    adminGetLeadClientList().then((x) => {
      setProjects(
        x.map((y) => {
          return { label: y.name, value: y.lrid };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (selected != null) {
      adminGetNumberStats({ pid: selected }).then((x) => {
        if (!x.error) {
          setNumbers(x);
        }
      });
    }
  }, [selected]);

  const removeNumbers = () => {
    if (window.confirm("Vai vēlaties izņemt visus numurus no šī saraksta?")) {
      adminRemoveNumbers({ pid: selected }).then(() => {
        alert("Success!");
        window.location.reload();
      });
    }
  };
  return (
    <Card withBorder>
      <Title order={3}>Pārvaldīt numurus</Title>
      <small>
        Šeit ir iespējams noņemt numurus no tekošā saraksta, vēlāk būs iespēja
        atgriezt tos arī atpakaļ. Ļoti labi der, ja, piemēram, šobrīdējie numuri
        nenes gana labus rezultātus
      </small>
      <Select
        style={{ marginTop: 15 }}
        searchable
        dropdownPosition="bottom"
        value={selected}
        data={projects && projects.length > 0 ? projects : []}
        label={intl.formatMessage({ id: "projects" })}
        onChange={(x) => setSelected(x)}
        withinPortal
      />
      {selected && numbers && (
        <div style={{ marginTop: 20 }}>
          Pieejamie numuri:{numbers[0]?.empty_status_count}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 40,
            }}
          >
            {numbers[0]?.empty_status_count > 0 ? (
              <Button
                onClick={removeNumbers}
                style={{ backgroundColor: "red" }}
              >
                Noņemt numurus no kampaņas
              </Button>
            ) : (
              <></>
            )}
            <Button
              style={{ backgroundColor: "green" }}
              onClick={() =>
                (window.location.href = `/admin-imports?pid=${selected}`)
              }
            >
              Pievienot numurus kampaņai
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};
