import { Button, Card, Grid, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { adminGetNumberStats } from "../functions/api";

export const MainScreen = () => {
  const [loading, setLoading] = useState(null);
  const [stats, setStats] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    adminGetNumberStats().then((x) => {
      if (!x.error) {
        setStats(x);
      }
    });
  }, []);

  const handleMove = (item, url) => {
    setLoading(item);
    window.location.href = url;
  };
  return (
    <div style={{ margin: 10, marginTop: 10, paddingBottom: 20 }}>
      <Title order={4}>Actions</Title>
      <div>
        <div style={{ marginTop: 20 }}>
          <Grid style={{}}>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "indigo",
                }}
                loading={loading == "users"}
                onClick={() => handleMove("users", "/admin-users")}
              >
                {intl.formatMessage({ id: "users" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "green",
                }}
                loading={loading == "lead"}
                onClick={() => handleMove("lead", "/admin-lead-overview")}
              >
                {intl.formatMessage({ id: "leads_overview" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "orange",
                }}
                loading={loading == "import"}
                onClick={() => handleMove("import", "/admin-imports")}
              >
                {intl.formatMessage({ id: "import" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "numbers"}
                onClick={() => handleMove("numbers", "/admin-numbers")}
              >
                {intl.formatMessage({ id: "number_management" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "projects"}
                onClick={() => handleMove("projects", "/admin-projects")}
              >
                {intl.formatMessage({ id: "projects" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "teal",
                  flex: 1,
                }}
                loading={loading == "campaigns"}
                onClick={() => handleMove("campaigns", "/admin-campaigns")}
              >
                {intl.formatMessage({ id: "manage_number_campaigns" })}
              </Button>
            </Grid.Col>
            {/* <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "clients"}
                onClick={() => handleMove("clients", "/admin-clients")}
              >
                {intl.formatMessage({ id: "clients" })}
              </Button>
            </Grid.Col> */}
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        {stats && stats.length > 0 && <Title order={4}>Palikušie numuri</Title>}
        <Grid>
          {stats &&
            stats?.map((dt, index) => (
              <Grid.Col key={index} sm={6}>
                <Card
                  withBorder
                  style={
                    dt.empty_status_count < 300
                      ? { borderColor: "red" }
                      : dt.empty_status_count < 600
                      ? { borderColor: "orange" }
                      : {}
                  }
                >
                  <Title order={5}>{dt.name}</Title>
                  <div>{dt.empty_status_count}</div>
                </Card>
              </Grid.Col>
            ))}
        </Grid>
      </div>
    </div>
  );
};

{
  /* <div>
<h3>Import campaign</h3>
<MultiSelect label="Select clients" data={[]} />
<TextInput label="Campaign name" />
<Textarea label="Phone numbers" />
</div> */
}
