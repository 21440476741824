export const messages = {
  "lv-LV": {
    manage_number_campaigns: "Pārvaldīt nr sarakstu",
    clients_numbers: "Klienti / Numuri",
    times_called: "Zvanītās reizes",
    projects: "Projekti / Kampaņas",
    last_call: "Pēdējais zvans",
    didnt_pickup: "Nepacēla",
    not_interested: "Nav ieinteresēts",
    invalid_number: "Nederīgs numurs",
    client_card: "Klienta kartiņa",
    extra_fields: "Papildus dati",
    sentences: "Vairāki teikumi",
    text: "Teksts",
    invoices_only_to_creator: "Rādīt rēķinus tikai to izveidotājiem",
    add_client_card_field: "Pievienot klienta kartiņai lauku",
    client_card_field_name: "Klienta kartiņas lauka nosaukums",
    can_search_by: "Izmantot klienta meklēšanai",
    client_card_type: "Klienta kartiņa tips",
    number: "Numurs/cipars",
    address: "Adrese",
    port: "Ports",
    dark_logo: "Logo tumšos toņos",
    send_invoices_from: "Sūtīt rēķinus no e-pasta:",
    host: "Hosts",
    emails_should_be_added_settings_default:
      "Pievienojiet e-pastu administrēšanā pie noklusētajām vērtībām (izsūtīt e-pastu no)",
    smcrm_can_help:
      "Palīdzībai ar šo sadaļu (ja nav pieejama IT persona) sazinaties ar info@somecrm.com!",
    emails_should_be_added_settings:
      "Pievienojiet e-pastus administrēšanā pie izejošajiem e-pastiem",
    working_settings: "Darba gaitas iestatījumi",
    visual_identity: "Vizuālā identitāte",
    general: "Vispārīgie iestatījumi",
    default_values: "Noklusējuma vērtības",
    email_outgoing: "Izejošie e-pasti",
    email_incoming: "Ienākošie e-pasti",
    ACTIVE: "Aktīvs",
    show_all_files: "Rādīt visus failus",
    user_rights: "Lietotāju tiesības",
    campaign_more_info: "Lūdzu aizpildat vairāk lauku, lai palaistu kampaņu",

    draft: "Melnraksts",
    back: "Atpakaļ",
    saved: "Saglabāts!",
    saved_message: "Ieraksts veiksmīgi saglabāts!",
    launch_campaign: "Sākt kampaņu",
    list: "Datu saraksts",
    selected_emails: "Izvēlētie e-pasti",
    email_templates: "Epastu šabloni",
    client_list: "Klientu saraksti",
    campaigns: "Kampaņas",

    new: "Izveidot jaunu",
    campaign: "Kampaņa",
    campaign_name: "Kampaņas nosaukums",
    campaign_description: "Kampaņas apraksts",
    subject: "E-pasta tēma",
    send_from: "Sūtīt no e-pasta",
    start_on: "Sākt izsūtīt no",
    email_list: "E-pastu saraksts",
    email_template: "E-pastu šablons",
    notice_about_email: " ",
    view_template: "Apskatīt šablonu",
    view_list: "Apskatīt sarakstu",

    remove_all: "Noņemt visus",
    add_all: "Pievienot visus",
    uplaod_data_from_file: "Pievienot no faila",
    unique_emails: "Unikālie e-pasti",
    results_selected: "Izvēlētie rezultāti",
    results_found: "Atrastie rezultāti",
    override_saved: "Pārrakstīt iepriekšējos",
    email_list_name: "E-pastu saraksta nosaukums",
    email_list_description: "E-pastu saraksta apraksts",
    email_list_data: "E-pasta saraksta dati",
    add_to_list: "Pievienot sarakstam",

    automation: "Automatizācija",
    event_cancel: "Pasākums atcelts",
    cancel_event: "Atcelt pasākumu",
    open_in_maps: "Atvērt kartē",
    event_create: "Izveidot pasākumu",
    marketing: "Mārketings",
    include_in_campaign: "Iekļaut kampaņā",
    all: "Visi",
    physcial: "Fiziska persona",
    legal: "Juridiska persona",
    unmarked: "Nemarķētie",
    client_legal: "Klienta legālais status",
    exclude_in_campaign: "Neiekļaut kampaņā",
    template_title: "Šablona nosaukums",
    template_description: "Šablona apraksts",
    save_template: "Saglabāt šablonu",

    involved_clients: "Iesaistītie klienti",
    invoice_email_default_text: "Noklusētais e-pastu teksts rēķiniem",
    create_event: "Pievienot notikumu",
    creation_data: "Izveides datums",
    client_overview: "Klientu pārskats",
    header_color: "Galvenes krāsa",
    reverse_color: "Kontrasta krāsa",
    admin_use_calendar: "Kalendārs Adminiem",
    user_use_calendar: "Kalendārs lietotājiem",
    limit_clients: "Ierobežot klientu parādīšanu",
    limit_tasks: "Ierobežot uzdevumus",
    currency: "Valūta",
    invoice_name_start: "Rēķinu sākums",
    invoice_default_service: "Noklusētais rēķinu pakalpojums",
    invoice_default_price: "Noklusētā rēķinu cena",
    yes: "Jā",
    no: "Nē",
    euro: "EUR",
    usd: "USD",
    system_setting_screen: "Sistēmas iestatījumi",
    client_overview_screen: "Klientu pārskats",
    private_event: "Privāts ieraksts",
    cause_created: "Aizvēršanas iemesls izveidots!",
    too_short_or_empty: "Pārāk īss teksts!",
    no_new_notif: "Nav jaunu paziņojumu",
    go_to_calendar: "Doties uz kalendāru",
    calendar: "Kalendārs",
    edit_event: "Labot pasākumu",
    new_event: "Jauns pasākums",
    event_link: "Pasākuma links",
    new_event_name: "Notikuma/Pasākuma nosaukms",
    meeting: "Tikšanās",
    online_meeting: "Online tikšanās",
    event: "Pasākums",
    event_type: "Pasākuma veids",
    main_responsible: "Galvenais atbildīgais",
    involved_users: "Iesaistītie lietotāji",
    select_datetime_start: "Izvēlaties sākuma laiku",
    select_datetime_end: "Izvēlaties beigu laiku",
    event_description: "Pasākuma apraksts",
    campaign_done: "Kampaņa pabeigta!",
    my_tasks: "Mani uzdevumi",
    elses_tasks: " uzdevumi",
    spy_feature: "Apskaties citu lietotāju dienas tekošos uzdevumus!",
    somecrm_only:
      "Šī darbība ir tikai SOMECRM sistēmai! Lūdzu pārliecinaties vai links ir pareizs!",
    api_correct: "Savienojums veiksmīgs!",
    api_incorrect:
      "Savienojums neizdevās - lūdzu pārliecinaties vai atslēga un links ir pareizi! Pielieciet Bearer (un tad atstarpi) atslēgas sākumā!",
    verify_connection: "Pārbaudīt savienojumu",
    local: "Šī lapa",
    lead_clients: "Ārējie klienti",
    return: "Atgriezties",
    color: "Krāsa",
    link: "URL",
    key: "Autorizācijas atslēga",
    lead_client_management: "Ārējie klienti",
    client_reopened: "Klients iespējams atvērts atkārtoti",
    integration_tab: "Integrācijas",
    from: "No",
    to: "Uz",
    emails: "E-pasti",
    day: "diena",
    hour: "stunda",
    minute: "minūte",
    second: "sekunde",
    task_create: "izveidoja uzdevumu",
    task_close: "aizvēra uzdevumu",
    task_done: "pabeidza uzdevumu",
    task_comment: "atstāja komentāru",
    client_close: "aizvēra klientu",
    client_comment: "atstāja komentāru",
    client_create: "izveidoja klientu",
    client_file: "augšupielādēja failu",
    lead_create: "izveidoja jaunu klientu",
    invoice_void: "annulēja rēķinu",
    invoice_sent: "izsūtīja rēķinu",
    invoice_paid_partially: "marķēja rēķinu daļēji apmaksātu ",
    invoice_paid: "marķēja rēķinu kā apmaksātu: ",
    invoice_create: "izveidoja rēķinu",
    invoice_comment: "atstāja komentāru pie rēķina",
    activity: "Aktivitāte",
    not_found: "Nav rezultātu..",
    modify: "Modificēt ",
    create_new_user: "Izveidot jaunu lietotāju",
    username: "Lietotājvārds",
    name: "Vārds",
    surname: "Uzvārds",
    email: "E-pasts",
    password: "Parole",
    status: "Statuss",
    disabled: "Atslēgts",
    active: "Aktīvs",
    group_id: "Grupas ID",
    admin: "Administrators",
    user: "Lietotājs",
    lead: "Klientu piesaistītājs",
    cancel: "Atcelt",
    update_user: "Atjaunināt lietotāju",
    create_user: "Izveidot lietotāju",
    select_reason: "Lūdzu, izvēlieties iemeslu",

    incorrect_login: "Nepareizs e-pasts vai parole",
    sign_into_account: "Pierakstīties savā kontā",
    remember_me: "Atcerēties mani",
    forgot_password: "Aizmirsi paroli?",
    sign_in: "Pierakstīties",
    link_maybe_expired: "Saite varētu būt novecojusi",

    invalid_email: "E-pasts nav derīgs!",
    reset_password_text: "Atiestatīt paroli",
    enter_email: "Ievadiet e-pastu",
    reset_password_button: "Atiestatīt paroli",
    reset_password_done: "Gatavs! Pārbaudi savu e-pastu",

    pwreset_invalid_token:
      "Nederīgs atiestatīšanas kods vai parole. Lūdzu, mēģiniet vēlreiz.",
    pwreset_something_wrong: "Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlāk.",
    pwreset_error: "Kļūda!",
    return_to_login: "Atgriezties pie pieteikšanās",
    new_password: "Jaunā parole",
    repeat_password: "Atkārtota parole",

    delayed_tasks: "Kavētie uzdevumi",
    upcoming_tasks: "Nākamie uzdevumi",
    missed_invoices: "Nokavētie rēķini",

    search_client: "Meklēt klientu",

    pinned: "Piesprausts",
    due_in: "Jāizpilda līdz ",
    completed: "pabeigts ",
    missed: "Nokavēts ",
    amount: "Summa: ",
    pc_paid: "Apmaksāts",
    due_on: "Līdz",

    ago: "pirms",
    left: "palicis",
    created_by: "Izveidoja:",
    created_on: "Izveidots:",
    deadline: "Termiņš:",
    assigned_to: "Izpildītājs:",
    finished: "Pabeigts",
    closed: "Aizvērts",

    actions_title: "Darbības",
    invoices: "Rēķini",
    new_invoice: "Jauns rēķins",
    new_task: "Jauns uzdevums",
    new_client: "Jauns klients",
    add_source: "Pievienot avotu",
    closing_reasons: "Aizvēršanas iemesli",

    client_sources: "Klientu avoti",
    select_sources: "Izvēlēties avotus",

    open_new_tab: "atvērt jaunā cilnē",
    so_closed: "Slēgts: ",

    enter_four_symbols: "Lūdzu ievadi vismaz 4 simbolus uzdevuma nosaukumā",
    confirm_close:
      "Vai esat pārliecināts, ka vēlaties aizvērt šo uzdevumu bez jauna uzdevuma izveidošanas?",
    new_task_name: "Jaunā uzdevuma nosaukums",
    client_closing_reason: "Klienta aizvēršanas iemesls",
    comment: "Komentārs",
    why_closed: "Kāpēc klients tiek aizvērts?",
    task_description: "Uzdevuma apraksts",
    select_user_for_task: "Izvēlieties lietotāju, kuram piešķirt uzdevumu",
    select_datetime_next_action:
      "Izvēlieties datumu un laiku nākamajai darbībai",
    search_user: "Meklēt lietotāju",
    select_datetime: "Izvēlieties datumu un laiku",
    doesnt_work_client_task: "Nestrādā klienta/uzdevuma aizvēršanas gadījumā",
    drop_current_pin: "Noņemt pašreizējo piespraudi?",
    create_pin_new_task: "Izveidot piespraudi jaunajam uzdevumam?",
    close_task: "Aizvērt uzdevumu",
    complete_task: "Pabeigt uzdevumu",
    close_client: "Aizvērt klientu",

    call_client: "Zvanīt klientam",
    call_from: "No: ",
    call_to: "Uz: ",

    cc_answered: "Atbildēts",
    cc_cancelled: "Atcelts",
    cc_unavailable: "Nav pieejams",
    cc_unsupported: "Jūsu pārlūkprogramma neatbalsta audio elementu.",

    annuled: "Atcelts",
    paid: "SAMAKSĀTS",
    partially: "DALĪBENSKĀRT",
    unpaid: "NEAPSAMAKSĀTS",
    extended: "Pagarināts",
    due: "Apmaksāt līdz",
    date: "Datums:",
    total: "Kopā:",
    total_with_discount: "Kopā ar atlaidi:",
    issued_by: "Izveidoja:",
    paid_date: "Samaksāts:",

    add_location: "Pievienot atrašanās vietu",
    type_address: "Ierakstiet adresi šeit",

    dashboard: "Darba panelis",
    administration: "Administrācija",
    reports: "Pārskati",
    page: "lapa",
    open_user_menu: "Atvērt lietotāja izvēlni",
    open_main_menu: "Atvērt galveno izvēlni",

    pin: "Piespraust",
    unpin: "Atspraust",

    tasks_completed: "Pabeigtie uzdevumi",
    invoices_sent: "Nosūtītie rēķini",
    increase: "palielināt",
    decrease: "samazināt",
    compared_to_last_month: " salīdzinājums ar iepriekšējo mēnesi",

    option_to_sort_client:
      "Šeit jūs varat arī filtrēt klientus, kuriem vadītāji ir pārvaldījuši, aizvēruši un izveidojuši.",
    name_surname: "Vārds & Uzvārds",
    phone: "Tālrunis",
    responsible: "Atbildīgais",
    client_type: "Klienta tips",
    created_at: "Izveidots",
    enter_four_search:
      "Lūdzu, ievadiet vismaz 4 rakstzīmes meklēšanai, lai redzētu rezultātus",
    no_clients_found: "Nav atrasts neviens klients.",
    search: "Meklēšana",

    wip: "Darbs procesā.",
    clients: "Klienti",
    tasks: "Uzdevumi",
    calls: "Zvani",
    maps: "Kartes",
    users: "Lietotāji",
    leads_overview: "Potenciālo klientu pārskats",
    soon: "Drīzumā!",
    select_status: "Izvēlieties statusu",
    select_source: "Izvēlieties avotu",
    pick_date_range: "Izvēlieties datumu diapazonu",
    submit: "Iesniegt",
    ar_created_by: "izveidoja ",
    select_user: "Izvēlieties lietotāju",
    select_users: "Izvēlieties lietotājus",
    select_grouping: "Izvēlieties grupēšanu",
    ar_created: "izveidots: ",
    select_caller: "Izvēlieties zvanītāju",
    select_client: "Izvēlieties klientu",
    enter_number: "Ievadiet numuru",
    call: "Zvans: ",
    select_sorting: "Izvēlieties kārtošanu",
    search_invoices: "Meklēt rēķinus...",
    ungrouped: "Negrupēts",
    ar_paid: "samaksāts: ",
    ar_due: "termiņā: ",
    select_system: "Izvēlieties sistēmu",
    without_date: "Bez datuma",
    // unpaid: "Nesamaksāts",
    void: "Nederīgs",
    ar_created_by_cap: "Izveidots ar",
    creation_range: "Izveides diapazons",
    due_date_range: "Termiņa diapazons",
    paid_date_range: "Samaksas datuma diapazons",
    sort_paid_desc: "Kārtot pēc samaksas datuma dilstoši",
    sort_created_desc: "Kārtot pēc izveides datuma dilstoši",
    sort_by: "Kārtot pēc",
    client_status: "Klienta statuss",
    client_creator: "Klienta veidotājs",
    client_source: "Klienta avots",
    closing_reason: "Aizvēršanas iemesls",
    closure_range: "Aizvēršanas diapazons",
    open: "Atvērt",
    ar_completed: "Pabeigts",
    ar_created_cap: "Izveidots",
    task_status: "Uzdevuma statuss",
    select_who_created: "Izvēlieties, kas izveidoja",
    completion_range: "Pabeigšanas diapazons",
    deadline_range: "Termiņa diapazons",
    group_by: "Grupēt pēc",
    who_called: "Kurš zvanīja",
    client: "Klients",
    client_number: "Klienta numurs",
    call_status: "Zvana statuss",
    call_answered: "Atbildēts",
    call_busy: "Aizņemts",
    ar_deadline: "Termiņš",
    system: "Sistēma",

    create_new_group: "Izveidot jaunu grupu",
    example_group: "Piemēra grupa",
    example_group_descr: "Šī ir piemēra grupa",
    edit_rights_group: "Rediģēt tiesību grupu",
    create_rights_group: "Izveidot tiesību grupu",
    enter_group_name: "Ievadiet grupas nosaukumu",
    enter_group_descr: "Ievadiet grupas aprakstu",
    view: "Skatīt",
    insert: "Ievietot",
    edit: "Rediģēt",
    delete: "Dzēst",
    group_name: "Grupas nosaukums",
    group_descr: "Grupas apraksts",

    transfer_task: "Pārvietot uzdevumus",
    number_management: "Zvanīšanas numuru pārvaldība",
    user_management: "Lietotāji",
    credential_management: "Rekvizītu pārvaldība",
    right_management: "Tiesības",
    import: "Imports",
    api_management: "API pārvaldība",
    nb_onlyfor: "NB: Tas ir tikai",
    teltel: "teltel",
    integration_and_numbers: "integrācijai un numuriem",
    add_new_number: "Pievienot jaunu numuru",
    save: "Saglabāt",
    add_number: "Pievienot numuru",
    number: "Numurs",
    api_key_part: "API ATSLĒGA (daļa no tās)",
    api_key: "API ATSLĒGA",
    tasks_transferred: "Uzdevumi pārvietoti!",
    as_tasks: "uzdevumi: ",
    as_unfinished: "neizpildītie: ",
    as_undelayed: "ar nenokavētiem termiņiem:",
    set_new_dealine: "Iestatīt jaunu termiņu?",
    move: "Pārvietot ",
    tasks_from: " uzdevumus no ",
    to: " uz ",
    add_edit_credentials: "Pievienot/Rediģēt Rekvizītus",
    cant_edit_credentials:
      "Ar šiem rekvizītu datiem jau ir izrakstīti rēķini - tos vairs nevar rediģēt, bet tos var deaktivēt, lai tos nevarētu izmantot turpmākajiem rēķiniem",
    user_for_this_num: "Lietotājs šim numuram",
    select_user_task_transfer_from:
      "Izvēlieties lietotāju, no kura pārvietot uzdevumus",
    select_user_task_transfer_to:
      "Izvēlieties lietotāju, kur pārvietot uzdevumus",
    new_deadline: "Jauns termiņš",
    company_name: "Uzņēmuma nosaukums",
    bank_name: "Bankas nosaukums",
    bank_account: "Bankas konts",
    bank_swift: "Bankas SWIFT",
    bank_iban: "IBAN",
    company_reg_num: "Uzņēmuma reģistrācijas numurs",
    jur_address: "Juridiskā adrese",
    fact_address: "Faktiskā adrese",
    vat: "PVN",
    credentials_infotxt:
      "Rēķina rekvizīti. Pēc pirmā rēķina izsniegšanas rekvizītus nevar mainīt!",
    add_credentials: "Pievienot akreditāciju",
    select_csv_import: "Izvēlieties CSV failu importēšanai",
    map_columns: "Atbilstošo lauku kartēšana",
    additional_details:
      "Papildu informācija - vai katram ir jāizveido uzdevums, un kam jāsaņem uzdevums?",
    completed_clickback: "Pabeigts, atgriezties iepriekšējā solī",
    sample_10rows: "Paraugu 10 rindas no datiem:",
    select_which_column:
      "Izvēlieties, kura kolonna atbilst kādai datu aprakstam",
    client_name: "Klienta vārds",
    client_surname: "Klienta uzvārds",

    clear: "Notīrīt",
    select_search_criteria: "Lūdzu, izvēlieties meklēšanas kritērijus",
    filtering_option:
      "Šeit jūs varat arī filtrēt pēc izveidotiem un aizvērtiem uzdevumiem.",
    all: "Visi",
    not_completed: "Nav pabeigts",
    assigned_user: "Piešķirts lietotājs",
    completed_date_from: "Pabeigšanas datums no",
    completed_date_to: "Pabeigšanas datums līdz",
    deadline_date_from: "Termiņa datums no",
    deadline_date_to: "Termiņa datums līdz",
    results: "Rezultāti:",

    search_users: "Meklēt lietotājus",
    group: "Grupa",

    user_baseinfo: "Lietotāja pamatinformācija",
    deactivate_user: "Deaktivēt lietotāju",
    activate_user: "Aktivēt lietotāju",
    user_terms: "Lietotāja noteikumi",
    user_group: "Lietotāja grupa",
    supervisor: "Vadītājs",
    pick_one: "Izvēlieties vienu",

    create_api: "Izveidot API",
    confirm_changes: "Vai tiešām vēlaties saglabāt izmaiņas?",
    api_online: "API ir tiešsaistē!",
    api_offline: "API ir bezsaistē!",
    api_active_msg: "API ir aktīvs un saņem pieprasījumus!",
    api_inactive_msg: "API ir atspējots un nesaņem pieprasījumus",
    safety_lock: "Drošības bloķēšana",
    safety_lock_desc:
      "Drošības bloķēšana novērš API datu maiņu, kamēr tā ir iespējota",
    api_name: "API nosaukums",
    api_link: "API saite",
    api_status: "API statuss",
    assign_task: "Piešķirt uzdevumu:",
    description: "Apraksts",
    fields_api_recieves: "Lauki, ko API saņem",
    telephone: "Tālrunis",
    api_clients: "API klienti",
    copied_to_clipboard: "Nokopēts starpliktuvē",
    copied_to_clipboard_success: "Veiksmīgi nokopēts starpliktuvē.",

    add_file: "Pievienot failu!",
    add_name: "Pievienot nosaukumu!",
    file_name: "Faila nosaukums",
    upload_file: "Augšupielādēt failu",

    consultation: "Konsultācija",
    design: "Dizains",
    development: "Izstrāde",
    contract_signing_with_guarantee:
      "Līguma parakstīšana ar garantiju par lietas uzsākšanu",
    select_a_client: "Izvēlieties klientu!",
    add_a_service: "Pievienot pakalpojumu!",
    select_credentials: "Izvēlieties rekvizītus!",
    add_new_invoice: "Pievienot jaunu rēķinu",
    select_issuer_credentials: "Izvēlieties izdevēju / rekvizītus",
    search_credentials_company_name: "Meklēt rekvizītus pēc uzņēmuma nosaukuma",
    write_ltd_select: "Ievadiet SIA un izvēlieties pirmo",
    due_date: "Termiņa datums",
    enter_service_name: "Ievadiet pakalpojuma nosaukumu",
    cost: "Izmaksa",
    remove: "Noņemt",
    add_service: "Pievienot pakalpojumu",
    tax_rate: "Nodokļa likme",
    vat_hundredths: "PVN simtos (0.21 = 21% PVN)",
    subtotal: "Starpsumma:",
    tax: "Nodoklis:",
    total_due: "Kopsumma:",
    notes: "Piezīmes",
    create_invoice: "Izveidot rēķinu",

    task_name: "Uzdevuma nosaukums",
    add_task: "Pievienot uzdevumu",

    process: "Process",
    change_client: "Mainīt klientu",
    workers: "Darbinieki",
    user1: "Lietotājs 1",
    user1p1: "Lietotājs 1.1",
    user1p2: "Lietotājs 1.2",

    user2: "Lietotājs 2",
    user2p1: "Lietotājs 2.1",
    user2p2: "Lietotājs 2.2",

    user3: "Lietotājs 3",
    user3p1: "Lietotājs 3.1",
    user3p2: "Lietotājs 3.2",

    user4: "Lietotājs 4",
    user4p1: "Lietotājs 4.1",
    user4p2: "Lietotājs 4.2",

    user5: "Lietotājs 5",
    user5p1: "Lietotājs 5.1",
    user5p2: "Lietotājs 5.2",

    user6: "Lietotājs 6",
    user6p1: "Lietotājs 6.1",
    user6p2: "Lietotājs 6.2",

    ///

    events: "Notikumi",
    event1: "Notikums 1",
    event1p1: "Notikums 1.1",
    event1p2: "Notikums 1.2",

    event2: "Notikums 2",
    event2p1: "Notikums 2.1",
    event2p2: "Notikums 2.2",

    event3: "Notikums 3",
    event3p1: "Notikums 3.1",
    event3p2: "Notikums 3.2",

    event4: "Notikums 4",
    event4p1: "Notikums 4.1",
    event4p2: "Notikums 4.2",

    ///

    settings: "Iestatījumi",
    setting1: "Iestatījums 1",
    setting1p1: "Iestatījums 1.1",
    setting1p2: "Iestatījums 1.2",

    setting2: "Iestatījums 2",
    setting2p1: "Iestatījums 2.1",
    setting2p2: "Iestatījums 2.2",

    setting3: "Iestatījums 3",
    setting3p1: "Iestatījums 3.1",
    setting3p2: "Iestatījums 3.2",

    setting4: "Tehnoloģija 4",
    setting4p1: "Iestatījums 4.1",
    setting4p2: "Iestatījums 4.2",

    business_info: "Biznesa informācija",
    legal_name: "Juridiskais nosaukums",
    legal_name_ph: "Džons",
    reg_code: "Reģistrācijas kods",
    reg_code_ph: "Džons",
    vat_ph: "džons",
    jur_address_ph: "Džona iela 54, Rīga, Lv",
    fact_address_ph: "Džona sniegs",
    description_ph: "kek",

    client_data: "Klienta dati",
    contract_text: "Līguma teksts",
    contract_conditions: "Līguma noteikumi",
    contract_history: "Līguma vēsture",
    payment_history: "Apmaksas / rēķina vēsture",

    loading: "Ielādē...",
    confirm_send: "Vai tiešām vēlaties nosūtīt klientam?",
    confirm_mark_paid:
      "Vai tiešām vēlaties atzīmēt šo rēķinu kā apmaksātu par pilnu summu ",
    add_comment: "Pievienot komentāru",
    send_to_client: "Nosūtīt klientam",
    request_email_for_send:
      "Lai nosūtītu, pievienojiet klienta e-pastu kartītei!",
    mark_paid: "Atzīmēt kā apmaksātu",
    view_client_new_tab: "Skatīt klientu jaunā cilnē",
    jbmnj_nr: "JBMNJ Nr.",
    paid_at: "Apmaksāts ",
    services: "Pakalpojumi:",
    already_paid: "Jau samaksāts:",
    client_phone_nr: "Klienta tālruņa numurs: ",
    reg: "Reģistrācija: ",
    download_invoice: "Lejupielādēt rēķinu",
    download_modified_invoice: "Lejupielādēt modificēto rēķinu",
    add_comment_invoice: "Pievienot komentāru rēķinam",
    modify_invoice_paid_data: "Modificēt apmaksas datumu",
    confirm_void: "Vai tiešām vēlaties anulēt šo rēķinu?",
    enter_payment_date: "Ievadiet apmaksas datumu:",
    select_date: "Izvēlieties datumu",
    confirm: "Apstiprināt",
    edit_invoice: "Rediģēt rēķinu: ",
    issued_to: "Izrakstīts uz:",
    invoiced_for: "Rēķināts par:",
    total_vat: "Kopējā PVN:",
    paid_amount: "Samaksāta summa:",
    deadline_extended_to: "Termiņš pagarināts līdz:",
    successfully_sent: "Veiksmīgi nosūtīts!",
    sending: "Nosūtīšana...",
    invoice_paid_closed_at: "Rēķins samaksāts un slēgts:",

    client_closed_on: "Klients aizvērts: ",
    files: "Faili",
    issue_invoice: "Izveidot rēķinu",
    save_client: "Saglabāt klientu",
    comments: "Komentāri",
    contracts: "Līgumi",
    business_name: "Uzņēmuma nosaukums",
    business_name_ph: "SIA Jons",
    client_name_ph: "Jons",
    client_surname_ph: "Dohs",
    business_id: "Uzņēmuma reģistrācijas numurs",
    business_id_ph: "0000020",
    contact_info: "Kontaktinformācija",
    email_ph: "janisberzins@epasts.li",
    phone_nr: "Telefona numurs",
    phone_nr_ph: "+3712345678",
    phys_address: "Faktiskā adrese",
    phys_address_ph: "Jona iela 54, Rīga, Lv",
    client_bank_info: "Klienta bankas informācija",
    clients_bank: "Klienta banka",
    clients_bank_ph: "Swedbank",
    bank_swift_ph: "HABA00",
    bank_account_ph: "90LV00000000",

    unified_reg_nr: "Vienots reģistrācijas numurs",
    vat_payer_nr: "PVN maksātāja numurs",
    bank: "Banka",
    account: "Konts",
    code: "Kods",
    loading_document: "Notiek dokumenta ielāde...",
    download_now: "Lejupielādēt tagad!",
    supplier: "Piegādātājs",
    pay_until: "Samaksāt līdz: ",
    customer: "Klients",
    explanation: "Izklāsts",
    units: "Vienības",
    price: "Cena",
    sum: "Summa",
    pieces: "gab.",
    total_sum: "Kopējā summa",
    sum_to_pay: "Summa, kas jāmaksā",
    payment_method_transfer: "Maksājuma veids: Bankas pārskaitījums",
    invoice_prepared_electr:
      "Rēķins sagatavots elektroniski un ir derīgs bez paraksta",
    pay_here: "Maksāt šeit!",

    password_no_match: "Jaunais parole un atkārtota parole nesakrīt",
    password_incorrect: "Nepareiza parole!",
    password_missing: "Nav ievadīta parole",
    password_change: "Mainīt paroli",
    password_old: "Vecā parole",
    password_save: "Saglabāt paroli",
    photo: "Bilde",
    change: "Mainīt",
    first_name: "Vārds",
    last_name: "Uzvārds",
    email_address: "E-pasta adrese",
    save_profile_changes: "Saglabāt profila izmaiņas",

    add_new_reason: "Pievienot jaunu iemeslu",
    add: "Pievienot",

    must_enter_email_phone: "Jāievada e-pasts vai telefona numurs!",
    confirm_submit_no_deadline: "Vai tiešām vēlaties iesniegt to bez termiņa?",
    confirm_submit_no_descr: "Vai tiešām vēlaties iesniegt to bez apraksta?",
    insolvency: "Insolvence",
    mattresses: "Matrači",
    solar_panels: "Saules baterijas",
    countdown: "Atpakaļskaitīšana",
    being_sent_to: "Tiek nosūtīts uz",
    submitting_in: "Iesniegšana pēc",
    seconds: "sekundēm",
    form_submitted: "Pieteikums iesniegts!",
    submit_another: "Iesniegt vēl vienu pieteikumu",
    name_ph: "Ievadiet savu vārdu",
    surname_ph: "Ievadiet savu uzvārdu",
    phone_nr_enter_ph: "Ievadiet savu telefona numuru",
    email_enter_ph: "Ievadiet savu e-pastu",
    enter_valid_email: "Lūdzu, ievadiet derīgu e-pasta adresi",
    comment_enter_ph: "Ievadiet savu komentāru",
    submitting: "Iesniedz...",

    source_created_success: "Avots veiksmīgi izveidots!",
    enter_new_source: "Ievadiet jaunu avotu",
    source_name: "Avota nosaukums",
    existing_similar_sources: "Esošie līdzīgie avoti:",
    pick_bg_colour: "Izvēlieties fona krāsu",
    pick_text_colour: "Izvēlieties teksta krāsu",
    preview: "Priekšskatījums:",
    email_account_management: "E-pastu pārvaldība",
    add_email: "Pievienot e-pastu",
    allow_read: "Atļaut lasīt",
    allow_send: "Atļaut sūtīt",
    email_rights: "Epastu tiesības",
  },
  "en-GB": {
    client_reopened: "Client might have been reopened",
    day: "day",
    hour: "hour",
    minute: "minute",
    second: "second",
    task_create: "created a task for ",
    task_close: "closed a task for",
    task_done: "completed a task for",
    task_comment: "left a comment for",
    client_close: "closed client: ",
    client_comment: "left a comment for",
    client_create: "created a client: ",
    client_file: "uploaded a file for: ",
    lead_create: "created a new client: ",
    invoice_void: "voided an invoice:",
    invoice_sent: "sent out an invoice",
    invoice_paid_partially: "marked the invoice partially paid: ",
    invoice_paid: "marked the invoice paid: ",
    invoice_create: "created an invoice",
    invoice_comment: "left a comment under the invoice",
    activity: "Activity",
    not_found: "No results found..",
    modify: "Modify ",
    create_new_user: "Create New User",
    username: "Username",
    name: "Name",
    surname: "Surname",
    email: "E-mail",
    password: "Password",
    status: "Status",
    disabled: "Disabled",
    active: "Active",
    group_id: "Group Id",
    admin: "Admin",
    user: "User",
    lead: "Lead",
    cancel: "Cancel",
    update_user: "Update user",
    create_user: "Create user",
    select_reason: "Please select a reason",

    incorrect_login: "Incorrect email or password",
    sign_into_account: "Sign in to your account",
    remember_me: "Remember me",
    forgot_password: "Forgot your password?",
    sign_in: "Sign in",
    link_maybe_expired: "Link might have expired",

    invalid_email: "Email is not valid!",
    reset_password_text: "Reset your password",
    enter_email: "Enter e-mail",
    reset_password_button: "Reset password",
    reset_password_done: "Done! Check your e-mail",

    pwreset_invalid_token: "Invalid token or password. Please try again.",
    pwreset_something_wrong: "Something went wrong. Please try again later.",
    pwreset_error: "Error!",
    return_to_login: "Return to login",
    new_password: "New password",
    repeat_password: "Repeat password",

    delayed_tasks: "Delayed tasks",
    upcoming_tasks: "Upcoming tasks",
    missed_invoices: "Missed invoices",

    search_client: "Search client",

    pinned: "Pinned",
    due_in: "Due in ",
    completed: "completed ",
    missed: "Missed ",
    amount: "Amount: ",
    pc_paid: "Paid",
    due_on: "Due on",

    ago: "ago",
    left: "left",
    created_by: "Created by:",
    created_on: "Created on:",
    deadline: "Deadline:",
    assigned_to: "Assigned to: ",
    finished: "Finished",
    closed: "Closed",

    actions_title: "Actions",
    invoices: "Invoices",
    new_invoice: "New Invoice",
    new_task: "New Task",
    new_client: "New Client",
    add_source: "Add Source",
    closing_reasons: "Closing Reasons",

    client_sources: "Client Sources",
    select_sources: "Select sources",

    open_new_tab: "open in new tab",
    so_closed: "Closed: ",

    enter_four_symbols: "Please enter at least 4 characters in the task name",
    confirm_close:
      "Are you sure you want to close this task without creating a new one?",
    new_task_name: "New task Name",
    client_closing_reason: "Client closing reason",
    comment: "Comment",
    why_closed: "Why the client is being closed?",
    task_description: "Task description",
    select_user_for_task: "Select user to assign task",
    select_datetime_next_action: "Select date and time for next action",
    search_user: "Search user",
    select_datetime: "Select date and time",
    doesnt_work_client_task: "Doesn't work with client/task closing",
    drop_current_pin: "Drop current pin?",
    create_pin_new_task: "Create pin for the new task?",
    close_task: "Close task",
    complete_task: "Complete task",
    close_client: "Close client",

    call_client: "Call client",
    call_from: "From: ",
    call_to: "To: ",

    cc_answered: "Answered",
    cc_cancelled: "Cancelled",
    cc_unavailable: "Unavailable",
    cc_unsupported: "Your browser does not support the audio element.",

    annuled: "Annulled",
    paid: "PAID",
    partially: "PARTIALLY",
    unpaid: "UNPAID",
    extended: "Extended",
    due: "Due",
    date: "Date:",
    total: "Total:",
    total_with_discount: "Total with discount:",
    issued_by: "Issued By:",
    paid_date: "Paid Date:",

    add_location: "Add a location",
    type_address: "Type address here",

    dashboard: "Dashboard",
    administration: "Administration",
    reports: "Reports",
    page: "page",
    open_user_menu: "Open user menu",
    open_main_menu: "Open main menu",

    pin: "Pin",
    unpin: "Unpin",

    tasks_completed: "Tasks completed",
    invoices_sent: "Invoices sent",
    increase: "increase",
    decrease: "decrease",
    compared_to_last_month: " compared to last month",

    option_to_sort_client:
      "Here you can also filter by clients managed, closed, and created by managers.",
    name_surname: "Name & Surname",
    phone: "Phone",
    responsible: "Responsible",
    client_type: "Client Type",
    created_at: "Created At",
    enter_four_search:
      "Please enter at least 4 characters in the search to see results",
    no_clients_found: "No clients found.",
    search: "Search",

    wip: "Work in progress.",
    clients: "Clients",
    tasks: "Tasks",
    calls: "Calls",
    maps: "Maps",
    users: "Users",
    leads_overview: "Leads overview",
    soon: "Coming soon!",
    select_status: "Select status",
    select_source: "Select source",
    pick_date_range: "Pick date range",
    submit: "Submit",
    ar_created_by: "created by ",
    select_user: "Select user",
    select_users: "Select users",
    select_grouping: "Select grouping",
    ar_created: "created: ",
    select_caller: "Select caller",
    select_client: "Select client",
    enter_number: "Enter number",
    call: "Call: ",
    select_sorting: "Select sorting",
    search_invoices: "Search invoices...",
    ungrouped: "Ungrouped",
    ar_paid: "paid: ",
    ar_due: "due: ",
    select_system: "Select system",
    without_date: "Without date",
    // unpaid: "Unpaid",
    void: "Void",
    ar_created_by_cap: "Created By",
    creation_range: "Creation Range",
    due_date_range: "Due Date Range",
    paid_date_range: "Paid Date Range",
    sort_paid_desc: "Sort by paid date descending",
    sort_created_desc: "Sort by creation date descending",
    sort_by: "Sort By",
    client_status: "Client Status",
    client_creator: "Client Creator",
    client_source: "Client Source",
    closing_reason: "Closing Reason",
    closure_range: "Closure Range",
    open: "Open",
    ar_completed: "Completed",
    ar_created_cap: "Created",
    task_status: "Task Status",
    select_who_created: "Select who created",
    completion_range: "Completion Range",
    deadline_range: "Deadline Range",
    group_by: "Group by",
    who_called: "Who called",
    client: "Client",
    client_number: "Client Number",
    call_status: "Call Status",
    call_answered: "Answered",
    call_busy: "Busy",
    ar_deadline: "Deadline",
    system: "System",

    create_new_group: "Create new group",
    example_group: "Example group",
    example_group_descr: "This is an example group",
    edit_rights_group: "Edit Rights Group",
    create_rights_group: "Create Rights Group",
    enter_group_name: "Enter group name",
    enter_group_descr: "Enter group description",
    view: "View",
    insert: "Insert",
    edit: "Edit",
    delete: "Delete",
    group_name: "Group Name",
    group_descr: "Group Description",

    transfer_task: "Transfer tasks",
    number_management: "Number management",
    user_management: "Users",
    credential_management: "Credential management",
    right_management: "Rights",
    import: "Import",
    api_management: "API",
    nb_onlyfor: "NB: This is only for",
    teltel: "teltel",
    integration_and_numbers: "integration and numbers",
    add_new_number: "Add new number",
    save: "Save",
    add_number: "Add number",
    number: "Number",
    api_key_part: "API KEY (part of it)",
    api_key: "API KEY",
    tasks_transferred: "Tasks transferred!",
    as_tasks: "tasks: ",
    as_unfinished: "unfinished: ",
    as_undelayed: "with undelayed due date:",
    set_new_dealine: "Set a new deadline?",
    move: "Move ",
    tasks_from: " tasks from ",
    to: " to ",
    add_edit_credentials: "Add/Edit credentials",
    cant_edit_credentials:
      "Invoices have already been issued with these credentials - they can no longer be edited, but can be deactivated so that they cannot be used for future invoices",
    user_for_this_num: "User for this number",
    select_user_task_transfer_from:
      "Select the user from which to transfer tasks",
    select_user_task_transfer_to: "Select the user to transfer tasks to",
    new_deadline: "New deadline",
    company_name: "Company Name",
    bank_name: "Bank Name",
    bank_account: "Bank Account",
    bank_swift: "Bank SWIFT",
    bank_iban: "IBAN",
    company_reg_num: "Company Registration Number",
    jur_address: "Juridical Address",
    fact_address: "Factual Address",
    vat: "VAT",
    credentials_infotxt:
      "Credentials for invoices. Credentials cannot be changed after the first invoice has been issued!",
    add_credentials: "Add credentials",
    select_csv_import: "Select a CSV file to import",
    map_columns: "Map columns with matching fields",
    additional_details:
      "Additional details - should a task be created for each, who should get the task?",
    completed_clickback: "Completed, click back button to get to previous step",
    sample_10rows: "Sample 10 rows from the data:",
    select_which_column: "Select which column fits which data description",
    client_name: "Client Name",
    client_surname: "Client Surname",

    clear: "Clear",
    select_search_criteria: "Please select search criteria",
    filtering_option: "Here you can also filter by tasks created and closed.",
    all: "All",
    not_completed: "Not completed",
    assigned_user: "Assigned User",
    completed_date_from: "Completed date from",
    completed_date_to: "Completed date to",
    deadline_date_from: "Deadline date from",
    deadline_date_to: "Deadline date to",
    results: "Results:",

    search_users: "Search users",
    group: "Group",

    user_baseinfo: "User Base Information",
    deactivate_user: "Deactivate User",
    activate_user: "Activate User",
    user_terms: "User Terms",
    user_group: "User Group",
    supervisor: "Supervisor",
    pick_one: "Pick one",

    create_api: "Create API",
    confirm_changes: "Do you really want to save the changes?",
    api_online: "API is online!",
    api_offline: "API is offline!",
    api_active_msg: "API is active and is receiving requests!",
    api_inactive_msg: "API is disabled and is not receiving requests",
    safety_lock: "Safety lock",
    safety_lock_desc:
      "The safety lock prevents changing API data while it is enabled",
    api_name: "API Name",
    api_link: "API link",
    api_status: "API status",
    assign_task: "Assign task:",
    description: "Description",
    fields_api_recieves: "Fields that API receives",
    telephone: "Telephone",
    api_clients: "API clients",
    copied_to_clipboard: "Copied to clipboard",
    copied_to_clipboard_success: "Successfully copied to the clipboard.",

    add_file: "Add a file!",
    add_name: "Add a name!",
    file_name: "File name",
    upload_file: "Upload file",

    consultation: "Consultation",
    design: "Design",
    development: "Development",
    contract_signing_with_guarantee:
      "Contract signing with a guarantee for case initiation",
    select_a_client: "Select a client!",
    add_a_service: "Add a service!",
    select_credentials: "Select credentials!",
    add_new_invoice: "Add a new invoice",
    select_issuer_credentials: "Select issuer / credentials",
    search_credentials_company_name: "Search for credentials by company name",
    write_ltd_select: "Enter SIA and select the first one",
    due_date: "Due date",
    enter_service_name: "Enter service name",
    cost: "Cost",
    remove: "Remove",
    add_service: "Add service",
    tax_rate: "Tax rate",
    vat_hundredths: "VAT in hundredths (0.21 = 21% VAT)",
    subtotal: "Subtotal:",
    tax: "Tax:",
    total_due: "Total due:",
    notes: "Notes",
    create_invoice: "Create invoice",

    task_name: "Task Name",
    add_task: "Add Task",

    process: "Process",
    change_client: "Change client",
    workers: "Employees",
    user1: "User 1",
    user1p1: "User 1.1",
    user1p2: "User 1.2",

    user2: "User 2",
    user2p1: "User 2.1",
    user2p2: "User 2.2",

    user3: "User 3",
    user3p1: "User 3.1",
    user3p2: "User 3.2",

    user4: "User 4",
    user4p1: "User 4.1",
    user4p2: "User 4.2",

    user5: "User 5",
    user5p1: "User 5.1",
    user5p2: "User 5.2",

    user6: "User 6",
    user6p1: "User 6.1",
    user6p2: "User 6.2",

    ///

    events: "Events",
    event1: "Event 1",
    event1p1: "Event 1.1",
    event1p2: "Event 1.2",

    event2: "Event 2",
    event2p1: "Event 2.1",
    event2p2: "Event 2.2",

    event3: "Event 3",
    event3p1: "Event 3.1",
    event3p2: "Event 3.2",

    event4: "Event 4",
    event4p1: "Event 4.1",
    event4p2: "Event 4.2",

    ///

    settings: "Settings",
    setting1: "Setting 1",
    setting1p1: "Setting 1.1",
    setting1p2: "Setting 1.2",

    setting2: "Setting 2",
    setting2p1: "Setting 2.1",
    setting2p2: "Setting 2.2",

    setting3: "Setting 3",
    setting3p1: "Setting 3.1",
    setting3p2: "Setting 3.2",

    setting4: "Technology 4",
    setting4p1: "Setting 4.1",
    setting4p2: "Setting 4.2",

    business_info: "Business info",
    legal_name: "Legal name",
    legal_name_ph: "John",
    reg_code: "Registration code",
    reg_code_ph: "John",
    vat_ph: "john",
    jur_address_ph: "John street 54, Riga, Lv",
    fact_address_ph: "jon snow",
    description_ph: "kek",

    client_data: "Client data",
    contract_text: "Contract text",
    contract_conditions: "Contract conditions",
    contract_history: "Contract history",
    payment_history: "Payment/Invoice history",

    loading: "Loading...",
    confirm_send: "Are you sure you want to send to the client?",
    confirm_mark_paid:
      "Are you sure you want to mark this as paid in the full amount of ",
    add_comment: "Add a comment",
    send_to_client: "Send to client",
    request_email_for_send: "To send, add the client's email to the card!",
    mark_paid: "Mark as paid",
    view_client_new_tab: "View the client in a new tab",
    jbmnj_nr: "JBMNJ Nr.",
    paid_at: "Paid at ",
    services: "Services:",
    already_paid: "Already paid:",
    client_phone_nr: "Client phone number: ",
    reg: "Registration: ",
    download_invoice: "Download invoice",
    download_modified_invoice: "Download modified invoice",
    add_comment_invoice: "Add a comment to the invoice",
    modify_invoice_paid_data: "Modify invoice paid date",

    confirm_void: "Are you sure you want to void this invoice?",
    enter_payment_date: "Enter payment date:",
    select_date: "Select date",
    confirm: "Confirm",
    edit_invoice: "Edit Invoice: ",
    issued_to: "Issued To:",
    invoiced_for: "Invoiced For:",
    total_vat: "Total VAT:",
    paid_amount: "Paid Amount:",
    deadline_extended_to: "Deadline extended to:",
    successfully_sent: "Successfully sent!",
    sending: "Sending...",
    invoice_paid_closed_at: "Invoice paid & closed at:",

    client_closed_on: "Client closed on: ",
    files: "Files",
    issue_invoice: "Issue Invoice",
    save_client: "Save client",
    comments: "Comments",
    contracts: "Contracts",
    business_name: "Business name",
    business_name_ph: "SIA Jon",
    client_name_ph: "Jon",
    client_surname_ph: "Dohh",
    business_id: "Business ID",
    business_id_ph: "0000020",
    contact_info: "Contact Info",
    email_ph: "janisberzins@email.li",
    phone_nr: "Phone number",
    phone_nr_ph: "+3712345678",
    phys_address: "Physical address",
    phys_address_ph: "John street 54, Riga, Lv",
    client_bank_info: "Client Bank info",
    clients_bank: "Client's Bank",
    clients_bank_ph: "Swedbank",
    bank_swift_ph: "HABA00",
    bank_account_ph: "90LV00000000",

    unified_reg_nr: "Unified registration Nr.",
    vat_payer_nr: "VAT payer Nr.",
    bank: "Bank",
    account: "Account",
    code: "Code",
    loading_document: "Loading document...",
    download_now: "Download now!",
    supplier: "Supplier",
    pay_until: "Pay until: ",
    customer: "Customer",
    explanation: "Explanation",
    units: "Units",
    price: "Price",
    sum: "Sum",
    pieces: "pcs",
    total_sum: "Total sum",
    sum_to_pay: "Sum to pay",
    payment_method_transfer: "Payment method: Bank transfer",
    invoice_prepared_electr:
      "Invoice prepared electronically and is valid without a signature",
    pay_here: "Pay here!",

    password_no_match: "New password and repeat password do not match",
    password_incorrect: "Password is not correct!",
    password_missing: "Password is missing",
    password_change: "Change password",
    password_old: "Old Password",
    password_save: "Save password",
    photo: "Photo",
    change: "Change",
    first_name: "First name",
    last_name: "Last name",
    email_address: "Email address",
    save_profile_changes: "Save profile changes",

    add_new_reason: "Add a new reason",
    add: "Add",

    must_enter_email_phone: "Email or phone number must be entered!",
    confirm_submit_no_deadline:
      "Are you sure you want to submit this without a deadline?",
    confirm_submit_no_descr:
      "Are you sure you want to submit this without a description?",
    insolvency: "Insolvency",
    mattresses: "Mattresses",
    solar_panels: "Solar panels",
    countdown: "Countdown",
    being_sent_to: "Being sent to",
    submitting_in: "Submitting in",
    seconds: "seconds",
    form_submitted: "Form submitted!",
    submit_another: "Submit another form",
    name_ph: "Enter your name",
    surname_ph: "Enter your surname",
    phone_nr_enter_ph: "Enter your phone number",
    email_enter_ph: "Enter your E-mail",
    enter_valid_email: "Please enter a valid email address",
    comment_enter_ph: "Enter your comment",
    submitting: "Submitting...",

    source_created_success: "Source created successfully!",
    enter_new_source: "Enter the new source",
    source_name: "Source name",
    existing_similar_sources: "Existing similar sources:",
    pick_bg_colour: "Pick background color",
    pick_text_colour: "Pick text color",
    preview: "Preview:",
    email_account_management: "E-mail management",
    add_email: "Add e-mail",
    allow_read: "Allow read",
    allow_send: "Allow send",
    email_rights: "Email rights",
    emails: "Emails",
    from: "From",
    to: "To",
    integration_tab: "Integrations",
    lead_client_management: "External lead clients",
    link: "URL",
    key: "Authorisation key",
    local: "This site",
    color: "Color",
    return: "Return",
    lead_clients: "External clients",
    my_tasks: "My tasks",
    elses_tasks: " tasks",
    spy_feature: "Spy on another users todays workload!",
    somecrm_only:
      "This works only for SOMECRM systems! Please make sure if the link is correct!",
    api_correct: "Connection sucessful1",
    api_incorrect:
      "Connection failed - please make sure that the URL and the key is correct. Make sure that the key has Bearer (and then a space) at the start of the key!",
    verify_connection: "Verify connection",
    new_event_name: "Event name",
    meeting: "Meeting",
    online_meeting: "Online meeting",
    event: "Event",
    event_type: "Event type",
    main_responsible: "Main responsible",
    involved_users: "Involved users",
    select_datetime_start: "Select starting time",
    select_datetime_end: "Select end time",
    event_description: "Event description",
    new_event: "New event",
    event_link: "Event link",
    calendar: "Calendar",
    edit_event: "Edit event",
    no_new_notif: "There are no new notifications",
    go_to_calendar: "Go to calendar",
    cause_created: "Closing cause created!",
    too_short_or_empty: "Input too short!",
    private_event: "Private entry",
    client_overview_screen: "Client overview",
    system_setting_screen: "System settings",
    header_color: "Headers color",
    reverse_color: "Contract color",
    admin_use_calendar: "Calendar for admins",
    user_use_calendar: "Calendar for users",
    limit_clients: "Limit displaying clinets",
    limit_tasks: "Limit seeing tasks",
    currency: "Currency",
    invoice_name_start: "Invoice naming",
    invoice_default_service: "Default invoice service",
    invoice_default_price: "Default invoice price",
    yes: "Yes",
    no: "No",
    euro: "EUR",
    usd: "USD",
    client_overview: "Client overview",
    creation_data: "Creation date",
    create_event: "Create event",
    invoice_email_default_text: "Default Invoice e-mail text",
    involved_clients: "Involved clients",

    include_in_campaign: "Include in campaign",
    all: "All",
    physcial: "Physical person",
    legal: "Legal person",
    unmarked: "Unmarket",
    client_legal: "Client legal status",
    exclude_in_campaign: "Exclude from campaign",
    template_title: "Template title",
    template_description: "Template description",
    save_template: "Save template",
    marketing: "Marketing",
    event_create: "Create an event",
    open_in_maps: "Open in maps",
    event_cancel: "Event cancelled",
    cancel_event: "Cancel event",
    automation: "Automation",

    remove_all: "Remove all",
    add_all: "Add all",
    uplaod_data_from_file: "Upload from file",
    unique_emails: "Unique e-mails",
    results_selected: "Selected results",
    results_found: "Found results",
    override_saved: "Override previously saved",
    email_list_name: "E-mail list name",
    email_list_description: "E-mail list description",
    email_list_data: "E-mail list data",
    add_to_list: "Add to list",

    campaign: "Campaign",
    campaign_name: "Campaign name",
    campaign_description: "Campaign description",
    subject: "E-mail subject",
    send_from: "Send e-mail from",
    start_on: "Start sending from",
    email_list: "E-mail list",
    email_template: "E-mail template",
    notice_about_email: " ",
    view_template: "View template",
    view_list: "View list",
    new: "Create new",
    list: "Data List",
    selected_emails: "Selected e-mails",

    email_templates: "E-mail templates",
    client_list: "Client lists",
    campaigns: "Campaigns",
    launch_campaign: "Launch campaign",
    saved: "Saved!",
    saved_message: "Entry has been saved successfully!",
    back: "Go back",
    draft: "Draft",

    working_settings: "Working settings",
    visual_identity: "Visual identity",
    general: "General settings",
    default_values: "Default values",
    email_outgoing: "Outgoing emails",
    email_incoming: "Incoming emails",

    show_all_files: "Show all files",
    user_rights: "User Rights",
    ACTIVE: "Active",
    emails_should_be_added_settings:
      "Add e-mails in Administration e-mail outgoing section",
    campaign_more_info: "Please fill more fields to launch the campaign!",
    port: "Port",
    campaign_done: "Campaign is complete!",
    host: "Host",
    dark_logo: "Logo in dark colours",
    send_invoices_from: "Send invoices from e-mail:",
    invoices_only_to_creator: "Show invoices only to creator",
    smcrm_can_help:
      "To get help with this section (if you don't have an IT staff available), contact info@somecrm.com!",
    emails_should_be_added_settings_default:
      "Add e-mail in Administration default values section (Send invoices from e-mail)",
    text: "Text",
    add_client_card_field: "Add a field to clients card",
    client_card_field_name: "Field name",
    can_search_by: "Search by it",
    client_card_type: "Field type",
    number: "Number",
    address: "Address",
    client_card: "Client Card",
    sentences: "Multiple sentences",
    extra_fields: "Extra data",
    didnt_pickup: "Didn't pick up",
    not_interested: "Not interested",
    invalid_number: "Invalid number",
    times_called: "Times called",
    last_call: "Last call",
    projects: "Projects / Campaigns",
    manage_number_campaigns: "Manage nr list",
  },
};

//<FormattedMessage id="pick_bg_colour" />
//useIntl().formatMessage({id: "pc_paid"})
//intl.formatMessage({id: "call"})
