import { getToken } from "./tokens";
// import { env } from "../env";

// export const url = "http://localhost:39211/"; //env.BACKEND_URL;
export const url = "https://b-marketing.jbcrm.lv/"; //env.BACKEND_URL;

const handleResponse = async (response) => {
  if (response.status == 200) {
    return await response.json();
  } else if (response.status == 404) {
    return { error: "Not found" };
  } else if (response.status == 400) {
    return { error: "error" };
  } else if (response.status == 401) {
    window.location.href = "/logout";
  }
};

export const createUser = async (data) => {
  const token = getToken();
  return fetch(url + `admin/create-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const updateUser = async (data) => {
  const token = getToken();
  return fetch(url + `admin/update-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUsers = async (
  search,
  status,
  access,
  group_id,
  sort_order,
  sort_column
) => {
  const token = getToken();
  const data = {
    search,
    status,
    access,
    group_id,
    sort_order,
    sort_column,
  };
  return fetch(url + `admin/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getSettings = async (data) => {
  const token = getToken();
  return fetch(url + "settings/get-settings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const checkResetToken = async (data) => {
  return fetch(url + `auth/reset-password-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const handleLogin = async (email, password, rememberMe) => {
  const response = await fetch(url + "auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, rememberMe }),
  });
  return response;
};

export const setNewPassword = (data) => {
  return fetch(url + `auth/change-pass`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const setPasswordResetToken = (data) => {
  return fetch(url + `auth/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: data }),
  }).then((response) => handleResponse(response));
};

export const getLastLeads = async () => {
  const token = getToken();
  return fetch(url + "lead/get-last-leads", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getLeadClientList = async () => {
  const token = getToken();
  return fetch(url + "lead/get-available-lead-clients", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const adminGetLeadClientList = async () => {
  const token = getToken();
  return fetch(url + "lead/get-all-lead-clients", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const submitLead = async (data) => {
  const token = getToken();
  return fetch(url + "lead/save-lead", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getReportFilters = () => {
  const token = getToken();
  return fetch(url + `report/get-filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getReportLeadResults = (data) => {
  const token = getToken();
  return fetch(url + `report/search-leads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const importNumbers = (data) => {
  const token = getToken();
  return fetch(url + `numbers/save-import`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getPhoneNumbers = () => {
  const token = getToken();
  return fetch(url + `admin/get-numbers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const updatePhoneNumber = (data) => {
  const token = getToken();
  return fetch(url + `admin/update-number`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getLeadNumber = (data) => {
  const token = getToken();
  return fetch(url + `lead/get-nr-project`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const declineLeadEntry = (data) => {
  const token = getToken();
  return fetch(url + `lead/decline-reason`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminGetNumberStats = (data) => {
  const token = getToken();
  return fetch(url + `admin/get-available-numbers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminGetNumberList = () => {
  const token = getToken();
  return fetch(url + `numbers/get-all-client-numbers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const adminSaveLeadClient = async (data) => {
  const token = getToken();
  return fetch(url + "lead/save-lead-client", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminVerifyLeadClientApi = async (data) => {
  return fetch(data.url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${data.token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminGetLeadClient = async (data) => {
  const token = getToken();
  return fetch(url + "lead/get-lead-client", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ lrid: data }),
  }).then((response) => handleResponse(response));
};

export const adminGetLeadListSelect = async () => {
  const token = getToken();
  return fetch(url + "lead/get-lead-list-for-select", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const adminRemoveNumbers = async (data) => {
  const token = getToken();
  return fetch(url + "admin/remove-numbers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};
